import React from 'react';
import {Box, makeStyles} from '@material-ui/core';
import clsx from "clsx";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {createStyles, Theme} from "@material-ui/core/styles";
import theme from "../../theme";


export default function AboutDe() {

    const {t, i18n} = useTranslation();
    const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            link: {
                fontWeight: 'bold',
                color: '#5D737E',
                '&:hover': {
                    textDecoration: 'none',
                    color: theme.palette.primary.main
                },
            }
        }),
    );
    const classes = useStyles(theme);

    return (
        <>
            <Box>

                <h1>Impressum</h1>

                <h3>Angaben gem&auml;&szlig; &sect; 5 TMG</h3>
                <p>Benjamin Armbruster IT Consulting<br/>
                    Lehrter Straße 25B<br/>
                    10557 Berlin</p>

                <h3>Kontakt</h3>
                <p>Telefon: +49-172-306 18 24<br/>
                    <img src="/em_data.png"/></p>

                <h3>Umsatzsteuer-ID</h3>
                <p>Umsatzsteuer-Identifikationsnummer gem&auml;&szlig; &sect; 27 a Umsatzsteuergesetz:<br/>
                    DE305191995</p>

                <h3>Redaktionell Verantwortlicher</h3>
                <p>Benjamin Armbruster<br/>
                    Lehrter Straße 25B<br/>
                    10557 Berlin</p>

                <h3>Datenschutzerklärung</h3>
                <Link to="/privacy" className={clsx(classes.link)}>{t('Privacy Policy')}</Link>

                <h3>Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle</h3>
                <p>Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer
                    Verbraucherschlichtungsstelle teilzunehmen.</p>

                <h4>Haftung f&uuml;r Inhalte</h4>
                <p>Als Diensteanbieter sind wir gem&auml;&szlig; &sect; 7 Abs.1 TMG
                    f&uuml;r eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich.
                    Nach &sect;&sect; 8
                    bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, &uuml;bermittelte oder
                    gespeicherte
                    fremde Informationen zu &uuml;berwachen oder nach Umst&auml;nden zu forschen, die auf eine
                    rechtswidrige
                    T&auml;tigkeit hinweisen.</p> <p>Verpflichtungen zur Entfernung oder Sperrung der Nutzung von
                Informationen
                nach den allgemeinen Gesetzen bleiben hiervon unber&uuml;hrt. Eine diesbez&uuml;gliche Haftung ist
                jedoch
                erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung m&ouml;glich. Bei Bekanntwerden von
                entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.</p>
                <h4>Haftung f&uuml;r Links</h4> <p>Unser Angebot enth&auml;lt Links zu externen Websites Dritter, auf
                deren
                Inhalte wir keinen
                Einfluss haben. Deshalb k&ouml;nnen wir f&uuml;r diese fremden Inhalte auch keine
                Gew&auml;hr &uuml;bernehmen. F&uuml;r die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter
                oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf
                m&ouml;gliche Rechtsverst&ouml;&szlig;e &uuml;berpr&uuml;ft. Rechtswidrige Inhalte waren zum Zeitpunkt
                der
                Verlinkung nicht erkennbar.</p> <p>Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist
                jedoch
                ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von
                Rechtsverletzungen
                werden wir derartige Links umgehend entfernen.</p>
                <h4>Urheberrecht</h4>
                <p>Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem
                    deutschen
                    Urheberrecht. Die Vervielf&auml;ltigung, Bearbeitung, Verbreitung und jede Art der Verwertung
                    au&szlig;erhalb der Grenzen des Urheberrechtes bed&uuml;rfen der schriftlichen Zustimmung des
                    jeweiligen
                    Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur f&uuml;r den privaten, nicht
                    kommerziellen Gebrauch gestattet.</p>
                <p>Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte
                    Dritter
                    beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf
                    eine
                    Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei
                    Bekanntwerden
                    von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.</p>

                <p>Quelle: <a href="https://www.e-recht24.de">https://www.e-recht24.de</a></p>
            </Box>
        </>
    );
}

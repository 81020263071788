import i18n from "i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import {initReactI18next} from "react-i18next";
import {Typography} from "@material-ui/core";
import React from "react";

// FIXME: Continue with https://github.com/Lemoncode/i18next-example-typescript/tree/master/03_split_json
// in order to have translations in files
// (tip move them in a JSON file and import them)
const resources = {
    en: {
        translation: {
            "Home": "Home",
            "TermsAndMore": "About",
            "I offer Software Development Services as Freelancer.": "I offer Software Development Services as Freelancer.",
            "Professional Java Fullstack Developer": "Professional Java Fullstack Developer",
            "Those are my majors": "Those are my majors:",
            "Availability": "Availability",
            "AvailabilityContent": "Currently not available.",
            "AvailabilityContent2": "Most likely busy till the end of 04/2021.",
            "Business Profiles": "Business Profiles",
            "What to expect?": "What to expect?",
            "Expectations1": "High Engangement",
            "Expectations2": "Ownership",
            "Expectations3": "Credibility",
            "Expectations4": "Constant Improvement",
            "Expectations5": "Teamplayer",
            "Privacy Policy": "Privacy Policy",
            "CookieConsentText": "We use cookies in order to optimize our webpage and service. Do you allow marketing and performance cookies?",
            "CookieConsentMoreInfo": "More information:",
            "CookieConsentAccept": "Accept",
            "CookieConsentDecline": "Decline",
        }
    },
    de: {
        translation: {
            "Professional Java Fullstack Developer": "Freiberuflicher Java Fullstack Entwickler",
            "I offer Software Development Services as Freelancer.": "Ich biete Software Entwicklungs-Dienstleistungen als Freiberufler an.",
            "Those are my majors": "Das sind meine Kernkompetenzen:",
            "TermsAndMore": "Impressum",
            "Availability": "Verfügbarkeit",
            "AvailabilityContent": "Momentan nicht verfügbar.",
            "AvailabilityContent2": "Bis mindestens Ende April 2021 ausgebucht.",
            "Business Profiles": "Geschäftsnetzwerke",
            "What to expect?": "Was ich mitbringe",
            "Expectations1": "Hohe Motivation",
            "Expectations2": "Verantwortungsbewusstsein",
            "Expectations3": "Authentizität",
            "Expectations4": "Kontinuierliche Verbesserung",
            "Expectations5": "Teamgeist",
            "Privacy Policy": "Datenschutzerklärung",
            "CookieConsentText": "Wir verwenden Cookies um unsere Webseite und unseren Service zu optimieren. Marketing und Statistik Cookies zulassen?",
            "CookieConsentMoreInfo": "Mehr Informationen:",
            "CookieConsentAccept": "Annehmen",
            "CookieConsentDecline": "Ablehnen",
        }
    }
};

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources,
        whitelist: ['en', 'de'],
        keySeparator: false,
        fallbackLng: 'en',
        debug: true,
        interpolation: {
            escapeValue: false
        },

    });

export default i18n;
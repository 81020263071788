import React from 'react';
import {makeStyles} from '@material-ui/core/styles';

const LogoStyle = makeStyles({
    root: {
        color: '#5D737E',
        border: 1,
        fontWeight: 'bolder',
        textAlign: "center"
    }
});

// const LogoStyle = styled(Button)({
//     background: '#287094',
//     border: 0,
//     borderRadius: 0,
//     // boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
//     color: '#F6F6F6',
//     height: 42,
//     padding: '0 30px',
// });

export default function Logo() {
    const classes = LogoStyle();
    return <span className={classes.root} >Benjamin Armbruster<br/>IT CONSULTUNG</span>
}

import React from 'react';

import {useTranslation} from 'react-i18next';
import PrivacyDe from "./privacy/privacyDe";
import PrivacyEn from "./privacy/privacyEn";

export default function Privacy() {

    const { t, i18n } = useTranslation();
    if (i18n.language == 'de') {
        return (
            <PrivacyDe/>
        );
    } else {
        return (
            <PrivacyEn/>
        );
    }

}

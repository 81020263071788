import React from 'react';
import clsx from 'clsx';
import Home from './pages/home';
import {createStyles, Theme} from '@material-ui/core/styles';
import {BrowserRouter as Router, Link, Route} from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import IconButton from '@material-ui/core/IconButton';
import Container from '@material-ui/core/Container';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import {menuItems} from './menuItems';
import {Grid, Hidden, makeStyles} from '@material-ui/core';
import Logo from './components/Logo';
import Box from "@material-ui/core/Box";
import About from "./pages/about";
import {useTranslation} from 'react-i18next';
import Privacy from "./pages/privacy";
import CookieConsent from "react-cookie-consent";

export default function App() {

    const drawerWidth = 240;
    const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            root: {
                display: 'flex',
            },
            toolbar: {
                paddingRight: 24, // keep right padding when drawer closed
            },
            toolbarIcon: {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                padding: '0 8px',
                ...theme.mixins.toolbar,
            },
            appBar: {
                zIndex: theme.zIndex.drawer + 1,
                transition: theme.transitions.create(['width', 'margin'], {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
            },
            appBarMenuSmUp: {
                listItem: {
                    float: 'left',
                    width: 'auto'
                }
            },
            appBarShift: {
                marginLeft: drawerWidth,
                width: `calc(100% - ${drawerWidth}px)`,
                transition: theme.transitions.create(['width', 'margin'], {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.enteringScreen,
                }),
            },
            menuButton: {
                marginRight: 36,
            },
            menuButtonHidden: {
                display: 'none',
            },
            title: {
                flexGrow: 1,
            },
            navDisplayFlex: {
                display: `flex`,
                justifyContent: `space-between`,
            },
            drawerPaper: {
                position: 'relative',
                whiteSpace: 'nowrap',
                width: drawerWidth,
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.enteringScreen,
                }),
            },
            drawerPaperClose: {
                overflowX: 'hidden',
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
                width: theme.spacing(7),
                [theme.breakpoints.up('sm')]: {
                    width: theme.spacing(9),
                },
            },
            appBarSpacer: theme.mixins.toolbar,
            contentClass: {
                clear: 'both',
                position: 'fixed',
                width: '100%',
                flexGrow: 1,
                height: '100vh',
                overflow: 'auto',
                margin: 'auto',
            },
            container: {
                paddingTop: theme.spacing(4),
                paddingBottom: theme.spacing(4),
            },
            paper: {
                padding: theme.spacing(2),
                display: 'flex',
                overflow: 'auto',
                flexDirection: 'column',
            },
            fixedHeight: {
                height: 240,
            },
            link: {
                fontWeight: 'bold',
                color: '#5D737E',
                '&:hover': {
                    textDecoration: 'none',
                    color: theme.palette.primary.main
                },
            }
        }),
    );


    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const handleDrawerOpen = () => {
        setOpen(true);
    };
    const handleDrawerClose = () => {
        setOpen(false);
    };
    const {t, i18n} = useTranslation();
    // const linkPrivacy = (props: CookieBanner.Props) => <Link to="/privacy" {...props} className={clsx(classes.link)}>{t('Privacy Policy')}</Link>;

    // @ts-ignore
    return (
        <div className={clsx(classes.root)}>
            <CssBaseline/>
            <Router>
                <AppBar position="relative" className={clsx(classes.appBar, open && classes.appBarShift)}>
                    <Toolbar className={clsx(classes.toolbar)}>
                        <Hidden mdUp>
                            <IconButton
                                edge="start"
                                color="inherit"
                                aria-label="open drawer"
                                onClick={handleDrawerOpen}
                                className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
                            >
                                <MenuIcon/>
                            </IconButton>
                        </Hidden>

                        <Logo/>

                        <Hidden smDown>
                            <List
                                component="nav"
                                aria-labelledby="main navigation"
                                className={clsx(classes.appBarMenuSmUp)}
                            >{menuItems(false, handleDrawerClose)}</List>
                        </Hidden>
                    </Toolbar>
                </AppBar>
                <Drawer
                    variant="temporary"
                    classes={{
                        paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
                    }}
                    open={open}
                >
                    <div className={clsx(classes.toolbarIcon)}>
                        <IconButton onClick={handleDrawerClose}>
                            <ChevronLeftIcon/>
                        </IconButton>
                    </div>
                    {/*<Divider/>*/}
                    <List>{menuItems(true, handleDrawerClose)}</List>
                </Drawer>

                <main className={clsx(classes.contentClass)}>
                    <div className={clsx(classes.appBarSpacer)}/>
                    <Container maxWidth="lg" className={clsx(classes.container)}>
                        <Route path="/" exact component={Home}/>
                        <Route path="/home" exact component={Home}/>
                        <Route path="/about" exact component={About}/>
                        <Route path="/privacy" exact component={Privacy}/>
                    </Container>
                    <Grid container direction="row" justify="space-between">
                        <Grid item>
                            <Box pt={4}>
                                Copyright &copy; {new Date().getFullYear()} Benjamin Armbruster
                            </Box>
                        </Grid>
                        <Grid item>
                            <Box pt={4}> <Link to="/privacy" className={clsx(classes.link)}>{t('Privacy Policy')}</Link>
                            </Box>
                        </Grid>
                    </Grid>
                    <CookieConsent
                        location="bottom"
                        buttonText={t('CookieConsentAccept')}
                        declineButtonText={t('CookieConsentDecline')}
                        cookieName="cookiesAccepted"
                        style={{ background: "#2B373B" }}
                        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
                        declineButtonStyle={{ color: "#4e503b", fontSize: "13px" }}
                        enableDeclineButton={true}
                        expires={150}
                    >
                        {t('CookieConsentText')}<br/>
                        {t('CookieConsentMoreInfo')} <Link to="/privacy" className={clsx(classes.link)}>{t('Privacy Policy')}</Link>
                    </CookieConsent>
                </main>
            </Router>

        </div>
    );
}

import React from 'react';

import {useTranslation} from 'react-i18next';
import AboutDe from "./about/aboutDe";
import AboutEn from "./about/aboutEn";

export default function About() {

    const { t, i18n } = useTranslation();
    if (i18n.language == 'de') {
        return (
            <AboutDe/>
        );
    } else {
        return (
            <AboutEn/>
        );
    }

}

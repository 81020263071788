import React from 'react';
import {createStyles, Theme} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import theme from "../theme";
import clsx from "clsx";
import {Card, CardMedia, Link, ListItem, ListItemIcon, ListItemText, makeStyles, Typography} from '@material-ui/core';
import List from '@material-ui/core/List';
import StarIcon from '@material-ui/icons/Star';
import {useTranslation} from 'react-i18next';

export default function Home() {

    const drawerWidth = 240;
    const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            drawerPaper: {
                position: 'relative',
                whiteSpace: 'nowrap',
                width: drawerWidth,
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.enteringScreen,
                }),
            },
            drawerPaperClose: {
                overflowX: 'hidden',
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
                width: theme.spacing(7),
                [theme.breakpoints.up('sm')]: {
                    width: theme.spacing(9),
                },
            },
            appBarSpacer: theme.mixins.toolbar,
            content: {
                flexGrow: 1,
                height: '100vh',
                overflow: 'auto',
            },
            contentSubTitles: {
                background: 'rgb(29, 29, 31)',
                color: 'white',
                borderRadius: 3,
                textAlign: 'center'
            },
            container: {
                paddingTop: theme.spacing(4),
                paddingBottom: theme.spacing(4),
            },
            paper: {
                padding: theme.spacing(2),
                display: 'flex',
                overflow: 'auto',
                flexDirection: 'column',
            },
            imageWrapper: {
                minHeight: '250px',
                maxWidth: '200px',
                margin: 'auto',
            },
            image: {
                backgroundPositionY: '20%',
                height: '250px',
            },
            link: {
                fontWeight: 'bold',
                color: '#5D737E',
                '&:hover': {
                    textDecoration: 'none',
                    color: theme.palette.primary.main
                },
            }
        }),
    );

    const {t, i18n} = useTranslation();

    const skills = [
        'Java 11',
        'Spring Boot Framework',
        'Rest Webservices',
        'High Performance/Scalability Cloud Application Development',
        'Resilient Software Design',
        'Load Testing'
    ]

    const classes = useStyles(theme);
    const [open, setOpen] = React.useState(false);
    const handleDrawerOpen = () => {
        setOpen(true);
    };
    const handleDrawerClose = () => {
        setOpen(false);
    };

    // @ts-ignore
    return (
        <>
            <Grid container spacing={3} alignItems="center">
                <Grid item xs={12}>
                    <Typography variant="h3" gutterBottom>
                        {t('Professional Java Fullstack Developer')}
                    </Typography>
                    <Typography variant="subtitle1" gutterBottom>
                        {t('I offer Software Development Services as Freelancer.')}<br/><br/>
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Card className={clsx(classes.imageWrapper)}>
                        <CardMedia
                            className={clsx(classes.image)}
                            image="./benjamin_armbruster.jpg"
                            title="Benjamin Armbruster"
                        />
                    </Card>
                </Grid>
                <Grid item xs={12} sm={8}>
                    <Typography variant="subtitle1" gutterBottom>
                        {t('Those are my majors')}
                    </Typography>
                    <List>
                        {skills.map((skill) => (
                            <ListItem key={"skill-" + skill}>
                                <ListItemIcon><StarIcon/></ListItemIcon>
                                <ListItemText><Typography variant="body1"
                                                          gutterBottom>{skill}</Typography></ListItemText>
                            </ListItem>
                        ))}
                    </List>
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={4}>
                    <Typography variant="h6" className={clsx(classes.contentSubTitles)} gutterBottom>
                        {t('Availability')}
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        {t('AvailabilityContent')}<br/>
                        {t('AvailabilityContent2')}
                    </Typography>
                </Grid>

                <Grid item xs={12} sm={4}>
                    <Typography variant="h6" className={clsx(classes.contentSubTitles)} gutterBottom>
                        {t('Business Profiles')}
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        <Link href="https://www.xing.com/profile/Benjamin_Armbruster" className={clsx(classes.link)}
                              target='_blank'>XING</Link><br/>
                        <Link href="https://www.linkedin.com/in/benjamin-armbruster" className={clsx(classes.link)}
                              target='_blank'>LinkedIn</Link>
                    </Typography>

                </Grid>

                <Grid item xs={12} sm={4}>
                    <Typography variant="h6" className={clsx(classes.contentSubTitles)} gutterBottom>
                        {t('What to expect?')}
                    </Typography>
                        <ul>
                            <li key="exp1"><ListItemText>{t('Expectations1')}</ListItemText></li>
                            <li key="exp2"><ListItemText>{t('Expectations2')}</ListItemText></li>
                            <li key="exp3"><ListItemText>{t('Expectations3')}</ListItemText></li>
                            <li key="exp4"><ListItemText>{t('Expectations4')}</ListItemText></li>
                            <li key="exp5"><ListItemText>{t('Expectations5')}</ListItemText></li>
                        </ul>
                </Grid>
            </Grid>
        </>
    );
}

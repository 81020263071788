import red from '@material-ui/core/colors/red';
import { createMuiTheme } from '@material-ui/core/styles';
import { deDE, enUS } from '@material-ui/core/locale';
// A custom theme for this app
const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#02111B',
    },
    secondary: {
      main: '#FCFCFC',
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#FFF',
    },
  },
}, deDE);

export default theme;

import React from 'react';
import {Box, makeStyles} from '@material-ui/core';
import {useTranslation} from "react-i18next";
import {createStyles, Theme} from "@material-ui/core/styles";
import theme from "../../theme";
import clsx from "clsx";
import {Link} from "react-router-dom";


export default function AboutEn() {

    const {t, i18n} = useTranslation();
    const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            link: {
                fontWeight: 'bold',
                color: '#5D737E',
                '&:hover': {
                    textDecoration: 'none',
                    color: theme.palette.primary.main
                },
            }
        }),
    );
    const classes = useStyles(theme);


    return (
        <Box>
            <h1>About</h1>

            <h2>Legal Notice</h2>

            <h3>Information provided according to Sec. 5 German Telemedia Act (TMG)</h3>
            <p>Benjamin Armbruster IT Consulting<br/>
                Lehrter Straße 25B<br/>
                10557 Berlin</p>

            <h3>Contact</h3>
            <p>Telefon: +49-172-306 18 24<br/>
            <img src="/em_data.png" /></p>

            <h3>Umsatzsteuer-ID</h3>
            <p>Umsatzsteuer-Identifikationsnummer gem&auml;&szlig; &sect; 27 a Umsatzsteuergesetz:<br/>
                DE305191995</p>

            <h3>Represented by</h3>
            <p>Benjamin Armbruster<br/>
                Lehrter Straße 25B<br/>
                10557 Berlin</p>

            <h3>Privacy Policy</h3>
            <Link to="/privacy" className={clsx(classes.link)}>{t('Privacy Policy')}</Link>

            <h3>Copyright</h3>
            <p>Contents and compilations published on these websites by the providers are subject to German copyright laws. Reproduction, editing, distribution as well as the use of any kind outside the scope of the copyright law require a written permission of the originator. The commercial use of our contents without permission of the originator is prohibited.</p>


            <h2>Disclaimer for Benjamin Armbruster IT Consultung</h2>

            <p>If you require any more information or have any questions about our site's disclaimer, please feel free to contact us by email.<br/>
                <img src="/em_data.png" /><br/>
                Our Disclaimer was generated with the help of the <a href="https://www.disclaimergenerator.net/">Disclaimer Generator</a>.</p>

            <h3>Disclaimers for Benjamin Armbruster IT Consultung</h3>

            <p>All the information on this website - armbruster.berlin - is published in good faith and for general information purpose only. Benjamin Armbruster IT Consultung does not make any warranties about the completeness, reliability and accuracy of this information. Any action you take upon the information you find on this website (Benjamin Armbruster IT Consultung), is strictly at your own risk. Benjamin Armbruster IT Consultung will not be liable for any losses and/or damages in connection with the use of our website.</p>

            <p>From our website, you can visit other websites by following hyperlinks to such external sites. While we strive to provide only quality links to useful and ethical websites, we have no control over the content and nature of these sites. These links to other websites do not imply a recommendation for all the content found on these sites. Site owners and content may change without notice and may occur before we have the opportunity to remove a link which may have gone 'bad'.</p>

            <p>Please be also aware that when you leave our website, other sites may have different privacy policies and terms which are beyond our control. Please be sure to check the Privacy Policies of these sites as well as their "Terms of Service" before engaging in any business or uploading any information.</p>

            <h3>Consent</h3>

            <p>By using our website, you hereby consent to our disclaimer and agree to its terms.</p>

            <h3>Update</h3>

            <p>Should we update, amend or make any changes to this document, those changes will be prominently posted here.</p>

        </Box>
    );
}

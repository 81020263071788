import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import HomeIcon from '@material-ui/icons/Home';
import AssignmentIcon from '@material-ui/icons/Assignment';
import {Button, makeStyles} from "@material-ui/core";
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import i18n from "i18next";


const stylesHorizontal = makeStyles(theme => ({
    item: {
        width: 'auto',
        float: 'left'
    },
}));
const stylesVertical = makeStyles(theme => ({
    item: {},
}));

const changeLanguage = (language: string) => i18n.changeLanguage(language);

export const menuItems = (vertical: boolean, handleDrawerClose: () => void) => {
    const {t, i18n} = useTranslation();
    const classes = vertical ? stylesVertical() : stylesHorizontal();


    return (
        <>
            <ListItem button className={classes.item} component={Link} onClick={handleDrawerClose} to="/">
                <ListItemIcon>
                    <HomeIcon/>
                </ListItemIcon>
                <ListItemText primary={t('Home')}/>
            </ListItem>
            <ListItem button className={classes.item} component={Link} onClick={handleDrawerClose} to="/about">
                <ListItemIcon>
                    <AssignmentIcon/>
                </ListItemIcon>
                <ListItemText primary={t('TermsAndMore')}/>
            </ListItem>
            <ListItem button className={classes.item} onClick={handleDrawerClose}>
                <Button onClick={e => changeLanguage("en")}>🇬🇧</Button>
                <Button onClick={e => changeLanguage("de")}>🇩🇪</Button>
            </ListItem>

        </>
    );
}
